import React, { useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const UploadAssignmentForm = ({ orderNumber }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateFiles = (files) => {
    const allowedTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-publisher",
    ];

    const maxSize = 20 * 1024 * 1024; // 20MB

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please select a valid file type.");
        return false;
      }

      if (file.size > maxSize) {
        setError("File size exceeds 20MB limit. Please select a smaller file.");
        return false;
      }
    }

    return true;
  };

  const handleFileChange = (e) => {
    setError("");
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      if (validateFiles(files)) {
        setSelectedFiles(files);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedFiles.length === 0) {
      setError("Please select at least one file.");
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });
console.log("formData", ...formData);
    try {
      setLoading(true);
      const token = localStorage.getItem("jwt");
      await axios.post(
        `${apiUrl}orders/uploadassignment/${orderNumber}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
          },
        }
      );
      setSelectedFiles([]);
      setError("");
      setLoading(false);
      console.log("Files uploaded successfully");
    } catch (error) {
      console.error("Error uploading files:", error);
      setError(
        "An error occurred while uploading the files. Please try again."
      );
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group files">
          <input
            type="file"
            className="form-control"
            name="file"
            onChange={handleFileChange}
            multiple
          />
          <button className="btn btn-primary btn-custom mt-2" type="submit">
            Upload Your Assignment Files
          </button>
          {error && <div className="text-danger mt-2">{error}</div>}
        </div>
      </form>
    </>
  );
};

export default UploadAssignmentForm;
