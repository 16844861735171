const orderItems = {
  academicLevels: [
    { value: "High School", label: "High School" },
    { value: "Undergraduate (yrs. 1-2)", label: "Undergraduate (yrs. 1-2)" },
    { value: "Undergraduate (yrs. 3-4)", label: "Undergraduate (yrs. 3-4)" },
    { value: "Master's", label: "Master's" },
    { value: "Ph.D.", label: "Ph.D." },
  ],
  typesOfPaper: [
    { value: "Type of assignment", label: "Type of assignment" },
    { value: "Essay (any type)", label: "Essay (any type)" },
    { value: "Other", label: "Other" },
    { value: "Admission essay", label: "Admission essay" },
    { value: "Maths problem", label: "Maths problem" },
    { value: "Physics problem", label: "Physics problem" },
    { value: "Chemistry problem", label: "Chemistry problem" },
    { value: "Discussion post", label: "Discussion post" },
    { value: "Response post", label: "Response post" },
    {
      value: "Biology/physics/chemistry lab report",
      label: "Biology/physics/chemistry lab report",
    },
    { value: "Thesis", label: "Thesis" },
    { value: "Dissertation", label: "Dissertation" },
    { value: "Research proposal", label: "Research proposal" },
    {
      value: "Programming (chat with support)",
      label: "Programming (chat with support)",
    },
    { value: "Online class (negotiable)", label: "Online class (negotiable)" },
    { value: "Finance paper", label: "Finance paper" },
    { value: "Analytical essay", label: "Analytical essay" },
    { value: "Annotated bibliography", label: "Annotated bibliography" },
    { value: "Argumentative essay", label: "Argumentative essay" },
    { value: "Article review", label: "Article review" },
    { value: "Assessment", label: "Assessment" },
    { value: "Biographies", label: "Biographies" },
    { value: "Book review", label: "Book review" },
    { value: "Business plan", label: "Business plan" },
    { value: "Capstone project", label: "Capstone project" },
    { value: "Case study", label: "Case study" },
    { value: "Cause and effect essay", label: "Cause and effect essay" },
    {
      value: "Compare and contrast essay",
      label: "Compare and contrast essay",
    },
    { value: "Coursework", label: "Coursework" },
    { value: "Cover letter", label: "Cover letter" },
    { value: "Creative writing", label: "Creative writing" },
    { value: "Critical review", label: "Critical review" },
    { value: "Critical thinking", label: "Critical thinking" },
    { value: "Definition essay", label: "Definition essay" },
    { value: "Descriptive essay", label: "Descriptive essay" },
    { value: "Editing", label: "Editing" },
    { value: "Evaluation essay", label: "Evaluation essay" },
    { value: "Expository essay", label: "Expository essay" },
    { value: "Formatting", label: "Formatting" },
    { value: "Lab report", label: "Lab report" },
    { value: "Literary analysis", label: "Literary analysis" },
    { value: "Movie review", label: "Movie review" },
    { value: "Multiple choice questions", label: "Multiple choice questions" },
    { value: "Narrative essay", label: "Narrative essay" },
    { value: "Personal statement", label: "Personal statement" },
    { value: "Persuasive essay", label: "Persuasive essay" },
    { value: "Presentation or speech", label: "Presentation or speech" },
    { value: "Problem solving", label: "Problem solving" },
    { value: "Proofreading", label: "Proofreading" },
    { value: "Reaction paper", label: "Reaction paper" },
    { value: "Reflective essay", label: "Reflective essay" },
    { value: "Research paper", label: "Research paper" },
    { value: "Research proposal", label: "Research proposal" },
    { value: "Rhetorical analysis", label: "Rhetorical analysis" },
    { value: "Term paper", label: "Term paper" },
  ],
  deadlines: [
    { value: "14 Days", label: "14 Days" },
    { value: "7 Days", label: "7 Days" },
    { value: "5 Days", label: "5 Days" },
    { value: "3 Days", label: "3 Days" },
    { value: "2 Days", label: "2 Days" },
    { value: "24 Hours", label: "24 Hours" },
    { value: "8 Hours", label: "8 Hours" },
  ],
  subjects: [
    { value: "", label: "Select from the list" },
    { value: "Other", label: "Other" },
    { value: "Accounting", label: "Accounting" },
    { value: "African-American Studies", label: "African-American Studies" },
    { value: "Algebra", label: "Algebra" },
    { value: "Anthropology", label: "Anthropology" },
    { value: "Architecture", label: "Architecture" },
    { value: "Art", label: "Art" },
    { value: "Art, Theatre and Film", label: "Art, Theatre and Film" },
    { value: "Astronomy", label: "Astronomy" },
    { value: "Aviation", label: "Aviation" },
    { value: "Biology", label: "Biology" },
    { value: "Business", label: "Business" },
    {
      value: "Business and Entrepreneurship",
      label: "Business and Entrepreneurship",
    },
    { value: "Calculus", label: "Calculus" },
    { value: "Chemistry", label: "Chemistry" },
    { value: "Coding", label: "Coding" },
    { value: "Communication Strategies", label: "Communication Strategies" },
    { value: "Computer Science", label: "Computer Science" },
    { value: "Coursework", label: "Coursework" },
    { value: "Criminology", label: "Criminology" },
    { value: "Culture", label: "Culture" },
    { value: "Ecology", label: "Ecology" },
    { value: "Economics", label: "Economics" },
    { value: "Education", label: "Education" },
    { value: "Engineering", label: "Engineering" },
    { value: "English", label: "English" },
    { value: "Environmental Issues", label: "Environmental Issues" },
    { value: "Environmental studies", label: "Environmental studies" },
    { value: "Ethics", label: "Ethics" },
    { value: "Excel", label: "Excel" },
    {
      value: "Family and consumer science",
      label: "Family and consumer science",
    },
    { value: "Finance", label: "Finance" },
    { value: "Geography", label: "Geography" },
    { value: "Geology", label: "Geology" },
    { value: "Geometry", label: "Geometry" },
    { value: "Healthcare", label: "Healthcare" },
    { value: "History", label: "History" },
    {
      value: "International and Public Relations",
      label: "International and Public Relations",
    },
    { value: "Investments", label: "Investments" },
    { value: "IT, Web", label: "IT, Web" },
    { value: "Journalism", label: "Journalism" },
    { value: "Law", label: "Law" },
    { value: "Law and Legal Issues", label: "Law and Legal Issues" },
    { value: "Leadership Studies", label: "Leadership Studies" },
    { value: "Linguistics", label: "Linguistics" },
    { value: "Literature", label: "Literature" },
    { value: "Macroeconomics", label: "Macroeconomics" },
    { value: "Management", label: "Management" },
    { value: "Marketing", label: "Marketing" },
    { value: "Mathematics", label: "Mathematics" },
    { value: "Medicine", label: "Medicine" },
    { value: "Microeconomics", label: "Microeconomics" },
    { value: "Music", label: "Music" },
    { value: "Nursing", label: "Nursing" },
    { value: "Nutrition", label: "Nutrition" },
    { value: "Philosophy", label: "Philosophy" },
    { value: "Physics", label: "Physics" },
    { value: "Political Science", label: "Political Science" },
    { value: "Presentation/Powerpoint", label: "Presentation/Powerpoint" },
    { value: "Psychology", label: "Psychology" },
    { value: "Religion and Theology", label: "Religion and Theology" },
    { value: "Science", label: "Science" },
    { value: "Sociology", label: "Sociology" },
    { value: "Sport", label: "Sport" },
    { value: "Sports", label: "Sports" },
    { value: "Statistics", label: "Statistics" },
    { value: "Technology", label: "Technology" },
    { value: "Theater studies", label: "Theater studies" },
    { value: "Tourism", label: "Tourism" },
    { value: "World affairs", label: "World affairs" },
    { value: "World literature", label: "World literature" },
  ],
  time: [
    { value: "12 AM", label: "12 AM" },
    { value: "1 AM", label: "1 AM" },
    { value: "2 AM", label: "2 AM" },
    { value: "3 AM", label: "3 AM" },
    { value: "4 AM", label: "4 AM" },
    { value: "5 AM", label: "5 AM" },
    { value: "6 AM", label: "6 AM" },
    { value: "7 AM", label: "7 AM" },
    { value: "8 AM", label: "8 AM" },
    { value: "9 AM", label: "9 AM" },
    { value: "10 AM", label: "10 AM" },
    { value: "11 AM", label: "11 AM" },
    { value: "12 PM", label: "12 PM" },
    { value: "1 PM", label: "1 PM" },
    { value: "2 PM", label: "2 PM" },
    { value: "3 PM", label: "3 PM" },
    { value: "4 PM", label: "4 PM" },
    { value: "5 PM", label: "5 PM" },
    { value: "6 PM", label: "6 PM" },
    { value: "7 PM", label: "7 PM" },
    { value: "8 PM", label: "8 PM" },
    { value: "9 PM", label: "9 PM" },
    { value: "10 PM", label: "10 PM" },
    { value: "11 PM", label: "11 PM" },
  ],
  style: [
    { value: "MLA", label: "MLA" },
    { value: "APA", label: "APA" },
    { value: "Chicago/Turabian", label: "Chicago/Turabian" },
    { value: "Harvard", label: "Harvard" },
    { value: "Not applicable", label: "Not applicable" },
    { value: "Other", label: "Other" },
  ],
  onlinetype: [
    { value: "Exam (160USD for an A)", label: "Exam (160USD for an A)" },
    {
      value: "Proctored Exam (199USD for an A)",
      label: "Proctored Exam (199USD for an A)",
    },
    { value: "Test/Quiz (90USD)", label: "Test/Quiz (90USD)" },
    {
      value: "Proctored Test/Quiz (120USD)",
      label: "Proctored Test/Quiz (120USD)",
    },
    {
      value: "Managing 8 Weeks Class (800USD for an A)",
      label: "Managing 8 Weeks Class (800USD for an A)",
    },
    {
      value: "Managing 11-14 Weeks Class (900USD for an A)",
      label: "Managing 11-14 Weeks Class (900USD for an A)",
    },
  ],
};
export default orderItems;