import React, { useState, useEffect } from "react";
import axios from "axios";
import ChangePassordForm from "../../../../components/forms/UpdatePasswordForm";
import UpdateProfileForm from "../../../../components/forms/UpdateProfileForm";
const apiUrl = process.env.REACT_APP_APIURL;

const DashboardProfile = () => {
  const [loading, setLoading] = useState(true); 
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("jwt");
        console.log("token", token);
        const response = await axios.get(`${apiUrl}user/myprofile`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setUserData(response.data);
        setLoading(false); 
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setLoading(false); 
      }
    };

    fetchUserData(); 
  }, []);
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="row">
          <div className="col-md-8">
            <UpdateProfileForm userData={userData} />
          </div>
          <div className="col-md-4">
            <ChangePassordForm userData={userData} />
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardProfile;
