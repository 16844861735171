

import React from "react";
import logo from "../../../images/logo.png";


const Footer = () => {
  return (
    <div className="footer-area bg-color-161a1e pt-100 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-sm-12" data-cues="fadeIn">
            <div className="single-footer-widget">
              <a href="index.html" className="logo">
                <img src={logo} alt="logo" />
              </a>
              <p>
                Eassypearl is a unique online academic writing platform that
                connects talented writers with clients to work together towards
                achieving a satisfying high-quality paper in a given time frame.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-4">
            <div className="single-footer-widget">
              <h3>Contact Information</h3>
              <ul className="import-link ps-0 mb-0 list-unstyled">
                <li>
                  <a href="tel:+19853170769">+19853170769</a>
                </li>
                <li>
                  <a href="mailto:help@essaypearl.com">help@essaypearl.com</a>
                </li>
                <li>
                  <a href="https://api.whatsapp.com/send/?phone=+19853170769&text=Hello!">
                    WhatsApp
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-md-4">
            <div className="single-footer-widget">
              <h3>Legal</h3>
              <ul className="import-link ps-0 mb-0 list-unstyled">
                <li>
                  <a href="/termsandconditions">Terms & Conditions</a>
                </li>
                <li>
                  <a href="mailto:help@essaypearl.com">Help Center</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
