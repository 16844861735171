import React, { useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const ChangePassordForm = () => {
  const [cpassword, setCPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();

    try {
      if (!password || !cpassword) {
        setError("All password fields are required");
        return;
      }

      if (password !== cpassword) {
        setError("Passwords do not match");
        return;
      }

      setLoading(true);
      const token = localStorage.getItem("jwt");
      const response = await axios.post(
        `${apiUrl}user/changepassword`,
        {
          password,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setMessage(`An error occurred: ${error.response.data.message}`);
      } else {
        setMessage("An error occurred. Please try again.");
      }
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Clear error when typing in password
    if (error && e.target.value && cpassword) {
      setError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setCPassword(e.target.value);
    // Clear error when typing in confirm password
    if (error && e.target.value && password) {
      setError("");
    }
  };

  return (
    <>
      <h5>Update Password</h5>
      {message && (
        <div
          className={`alert ${
            message.includes("successfully") ? "alert-success" : "alert-danger"
          }`}
        >
          {message}
        </div>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handlePasswordUpdate}>
        <div className="row">
          <div className="form-group col-md-12 mb-2">
            <label>Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="form-group col-md-12 mb-2">
            <label>Confirm Password:</label>
            <input
              type="password"
              className="form-control"
              value={cpassword}
              onChange={handleConfirmPasswordChange}
            />
          </div>

          <div className="form-group col-md-12 mb-2">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Updating..." : "Update Password"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangePassordForm;
