import React, { useState } from "react";
import LoginFormAuth from "../forms/LoginFormAuth";
import RegisterFormAuth from "../forms/RegisterFormAuth";

const AuthController = ({ onLoginChange }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isRegistered, setIsRegistered] = useState(false);

  const toggleForm = () => {
    setIsLogin((prev) => !prev);
  };

  const handleRegistered = () => {
    setIsRegistered(true);
    setIsLogin(true);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h2>{isLogin ? "Login" : "Register"}</h2>

        {isRegistered && (
          <div className="alert alert-success" role="alert">
            Registration successful! Please login to continue.
          </div>
        )}

        {isLogin ? (
          <LoginFormAuth
            onLoginChange={onLoginChange}
            onRegistered={handleRegistered}
          />
        ) : (
          <RegisterFormAuth
            onLoginChange={onLoginChange}
            onRegistered={handleRegistered}
          />
        )}

        <p>
          {isLogin ? (
            <>
              Don't have an account?{" "}
              <span className="text-primary" onClick={toggleForm}>
                Register here.
              </span>
            </>
          ) : (
            <>
              Already have an account?{" "}
              <span className="text-primary thelink" onClick={toggleForm}>
                Login here.
              </span>
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default AuthController;
