import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ChatBox.css";

const apiUrl = process.env.REACT_APP_APIURL;

const ChatBox = ({ orderData, orderNumber }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const chatBoxRef = useRef(null); // Create a ref for the chat box

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await axios.get(
          `${apiUrl}chat/getmessages/${orderNumber}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setMessages(response.data);
        // After setting messages, scroll to the bottom
        scrollToBottom();
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    if (orderNumber) {
      fetchMessages();
      // Refresh messages every 10 seconds
      const interval = setInterval(fetchMessages, 10000);
      return () => clearInterval(interval);
    }
  }, [orderNumber]);

  const handleMessageSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!newMessage.trim()) {
      setErrorMessage("Message cannot be empty");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000); // Clear error message after 5 seconds
      return;
    }
    try {
      const token = localStorage.getItem("jwt");
      setLoading(true); // Set loading state to true when sending starts
      await axios.post(
        `${apiUrl}chat/addmessage/${orderNumber}`,
        { message: newMessage },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      // Clear the newMessage state
      setNewMessage("");
      setLoading(false); // Set loading state to false after message is sent
      // After sending message, scroll to the bottom
      scrollToBottom();
    } catch (error) {
      setLoading(false); // Set loading state to false if an error occurs
      setErrorMessage("Error sending message");
      setTimeout(() => {
        setErrorMessage("");
      }, 5000); // Clear error message after 5 seconds
      console.error("Error sending message:", error);
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  return (
    <div className="mt-4">
      <div className="chatbox chatbox-primary direct-chat direct-chat-primary">
        <div className="chatbox-header with-border">
          <h3 className="chatbox-title">Direct Chat</h3>
        </div>
        <div className="chatbox-body">
          <div className="direct-chat-messages" ref={chatBoxRef}>
            {messages.map((message) => (
              <div
                key={message.chat_id}
                className={`direct-chat-msg ${
                  message.c_type === 1 ? "right" : "left"
                }`}
              >
                <div className="direct-chat-info clearfix">
                  <span className="direct-chat-name">
                    {message.c_type === 1 ? "Me" : "EssayPearl"}{" "}
                    {/* Set sender name */}
                  </span>
                  <span className="direct-chat-timestamp">
                    {new Date(message.c_datesent).toLocaleString()}{" "}
                    {/* Display timestamp */}
                  </span>
                </div>
                <img
                  className="direct-chat-img"
                  src={
                    message.c_type === 1
                      ? "https://www.essaypearl.com/assets/images/essayfav.png"
                      : "https://www.essaypearl.com/assets/images/useravatar.png"
                  }
                  alt="Message User Image"
                />
                <div className="direct-chat-text">{message.c_message}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="chatbox-footer">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              name="message"
              placeholder="Write something..."
              aria-label="Write something..."
              aria-describedby="button-addon2"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              required
            />
            <button
              className="btn btn-outline-primary"
              name="btn_sendmessage"
              id="button-addon2"
              onClick={handleMessageSubmit}
              disabled={loading}
              type="button"
            >
              {loading ? "Sending..." : "Send"}
            </button>
          </div>
          {errorMessage && (
            <div className="text-danger" style={{ fontSize: "12px" }}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
