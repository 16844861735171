import React, { useState, useEffect } from "react";
import checkAuth from "../../utils/checkAuth";
import AuthController from "../../widgets/AuthController";

const Third = ({ selectedValues, onSelectedValuesChange }) => {
  const [login, setLogin] = useState("");

  useEffect(() => {
    const authStatus = checkAuth(); 
    setLogin(authStatus); 
    handleLoginChange(authStatus);
  }, [checkAuth]); 

  const handleLoginChange = (status) => {
    setLogin(status);
    onSelectedValuesChange("_login", status);
  };

  const userName = localStorage.getItem("fullname");
  const userEmail = localStorage.getItem("email");

  return (
    <>
      {login ? (
        <div>
          <p>Welcome, {userName}!</p>
          <p>Your email: {userEmail}</p>
        </div>
      ) : (
        <AuthController onLoginChange={handleLoginChange} />
      )}
    </>
  );
};
export default Third;
