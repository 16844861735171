import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import orderItems from "../../lists/orderItems";

const First = ({ selectedValues, onSelectedValuesChange }) => {
  const [selectedAcademicLevel, setSelectedAcademicLevel] = useState("");
  const [selectedTypeOfPaper, setSelectedTypeOfPaper] = useState("");
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [isDoubleSpaced, setIsDoubleSpaced] = useState(false);

  useEffect(() => {
    // Set initial state values based on selectedValues from the Order page
    setSelectedAcademicLevel(selectedValues._academicLevel);
    setSelectedTypeOfPaper(selectedValues._typeOfPaper);
    setSelectedDeadline(selectedValues._deadline);
    setSelectedTime(selectedValues._time);
    setSelectedSubject(selectedValues._subject);
    setPageCount(selectedValues._pageCount);
    setIsDoubleSpaced(selectedValues._spacing);
  }, [selectedValues]);

  const handleSelectChange = (key, value) => {
    // Update the parent component's state first
    onSelectedValuesChange(key, value);

    // Update the local state based on the selected key
    switch (key) {
      case "_academicLevel":
        setSelectedAcademicLevel(value);
        break;
      case "_typeOfPaper":
        setSelectedTypeOfPaper(value);
        break;
      case "_deadline":
        setSelectedDeadline(value);
        break;
      case "_time":
        setSelectedTime(value);
        break;
      case "_subject":
        setSelectedSubject(value);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (newNumber) => {
    if (!isNaN(newNumber) && newNumber >= 1) {
      onSelectedValuesChange("_pageCount", newNumber);
      setPageCount(newNumber);
    }
  };

  const handleSpacingChange = (e) => {
    const spacing = e.target.value;
    onSelectedValuesChange("_spacing", spacing);
    setIsDoubleSpaced(spacing === "double");
  };

  const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: "increment",
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
        onChange={(event, newNumber) => {
          handleInputChange(newNumber);
        }}
      />
    );
  });

  const academicLevelOptions = orderItems.academicLevels;
  const typeOfPaperOptions = orderItems.typesOfPaper;
  const deadlineOptions = orderItems.deadlines;
  const subjectOptions = orderItems.subjects;
  const timeOptions = orderItems.time;
  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-2">
          <label>Select Academic level</label>
          <Select
            options={academicLevelOptions}
            value={{
              value: selectedAcademicLevel,
              label: selectedAcademicLevel,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_academicLevel", selectedOption.value)
            }
            placeholder="Select Academic Level"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Select Type of Paper</label>
          <Select
            options={typeOfPaperOptions}
            value={{
              value: selectedTypeOfPaper,
              label: selectedTypeOfPaper,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_typeOfPaper", selectedOption.value)
            }
            placeholder="Select Type of Paper"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Select Subject</label>
          <Select
            options={subjectOptions}
            value={{
              value: selectedSubject,
              label: selectedSubject,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_subject", selectedOption.value)
            }
            placeholder="Select Subject"
          />
        </div>
        <div className="col-md-6 mb-2">
          <label>Select Deadline</label>
          <div className="row">
            <div className="col-md-6 mb-2">
              <Select
                options={deadlineOptions}
                value={{
                  value: selectedDeadline,
                  label:
                    deadlineOptions.find((d) => d.value === selectedDeadline)
                      ?.label || "",
                }}
                onChange={(selectedOption) =>
                  handleSelectChange("_deadline", selectedOption.value)
                }
                placeholder="Select Deadline"
              />
            </div>
            <div className="col-md-6 mb-2">
              <Select
                options={timeOptions}
                value={{
                  value: selectedTime,
                  label: selectedTime,
                }}
                onChange={(selectedOption) =>
                  handleSelectChange("_time", selectedOption.value)
                }
                placeholder="Select Time"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2 align-items-center">
        <div className="col-md-12 text-center">
          <p className="text-small">Enter number of Pages</p>
        </div>
        <div className="col-md-6 mb-2">
          <NumberInput
            aria-label="Quantity Input"
            value={pageCount}
            min={1}
            max={99}
            onChange={(event, newNumber) => {
              console.log(`${event.type} event: the new value is ${newNumber}`);
              this.handleInputChange(newNumber);
            }}
          />
        </div>
        <div className="col-md-6 mb-2">
          <div className="col-md-6 mb-2">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="spacingRadio"
                id="doubleSpaced"
                value="double"
                checked={isDoubleSpaced}
                onChange={handleSpacingChange}
              />
              <label className="form-check-label" htmlFor="doubleSpaced">
                Double Spaced
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="spacingRadio"
                id="singleSpaced"
                value="single"
                checked={!isDoubleSpaced}
                onChange={handleSpacingChange}
              />
              <label className="form-check-label" htmlFor="singleSpaced">
                Single Spaced
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);
export default First;
