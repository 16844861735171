import React, { useState, useEffect } from "react";
import "../Order.css";
import First from "./First";
import Second from "./Second";
import Third from "./Third";
import orderItems from "../../lists/orderItems";
import pricingItems from "../../lists/pricingitems";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const apiUrl = process.env.REACT_APP_APIURL;
const OrderWizard = ({ passedValues }) => {
  // console.log( passedValues);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    _academicLevel:
      passedValues._academicLevel || orderItems.academicLevels[1].value,
    _typeOfPaper: passedValues._typeOfPaper || orderItems.typesOfPaper[1].value,
    _deadline: passedValues._deadline || orderItems.deadlines[0].value,
    _time: passedValues._time || orderItems.time[0].value,
    _subject: passedValues._subject || orderItems.subjects[1].value,
    _spacing: passedValues._spacing || "double",
    _pageCount: passedValues._pageCount || 1,
    _style: passedValues._style || orderItems.style[1].value,
    _topic: passedValues._topic || "",
    _instructions: passedValues._instructions || "",
    _login: passedValues._login || false,
    _references: passedValues._references || 0,
  });

  useEffect(() => {
    // Update selectedValues when passedValues changes
    setSelectedValues((prevValues) => ({
      ...prevValues,
      _academicLevel:
        passedValues._academicLevel || orderItems.academicLevels[1].value,
      _typeOfPaper:
        passedValues._typeOfPaper || orderItems.typesOfPaper[1].value,
      _deadline: passedValues._deadline || orderItems.deadlines[0].value,
      _time: passedValues._time || orderItems.time[0].value,
      _subject: passedValues._subject || orderItems.subjects[1].value,
      _spacing: passedValues._spacing || "double",
      _pageCount: passedValues._pageCount || 1,
      _style: passedValues._style || orderItems.style[1].value,
      _topic: passedValues._topic || "",
      _instructions: passedValues._instructions || "",
      _login: passedValues._login || false,
      _references: passedValues._references || 0,
    }));
  }, [passedValues]);

  const handleSelectedValuesChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const calculatePriceAndWords = () => {
    // console.log(
    //   "level: " +
    //     selectedValues._academicLevel +
    //     " deadline: " +
    //     selectedValues._deadline
    // );

    const wordsPerPage = 275;

    const pricingRow = pricingItems.find((item) =>
      item.data.some((row) => row[0] === selectedValues._deadline)
    );

    if (!pricingRow) {
      console.error("Selected deadline not found in pricing items");
      return null;
    }

    let columnIndex;
    switch (selectedValues._academicLevel) {
      case "High School":
        columnIndex = 1;
        break;
      case "Undergraduate (yrs. 1-2)":
        columnIndex = 2;
        break;
      case "Undergraduate (yrs. 3-4)":
        columnIndex = 3;
        break;
      case "Master's":
        columnIndex = 4;
        break;
      case "Ph.D.":
        columnIndex = 5;
        break;
      default:
        console.error("Selected academic level not recognized");
        return null;
    }

    const selectedPrice = pricingRow.data.find(
      (row) => row[0] === selectedValues._deadline
    )[columnIndex];

    // console.log("selected price: " + selectedPrice);
    const validPageCount = Number(selectedValues._pageCount);

    if (isNaN(validPageCount) || validPageCount < 1) {
      return { _wordCount: 0, _price: 0 };
    }
    const pricePerWord =
      parseFloat(selectedPrice.replace("$", "")) / wordsPerPage;
    const wordCount = validPageCount * wordsPerPage;

    const price = wordCount * pricePerWord;
    const spacingMultiplier = selectedValues._spacing === "double" ? 1 : 2;
    const adjustedWordCount = wordCount * spacingMultiplier;
    const adjustedPrice = price * spacingMultiplier;

    return { _wordCount: adjustedWordCount, _price: adjustedPrice || 0 };
  };



  const firstComponent = () => {
    return (
      <First
        selectedValues={selectedValues}
        onSelectedValuesChange={handleSelectedValuesChange}
      />
    );
  };
  const secondComponent = () => {
    return (
      <Second
        selectedValues={selectedValues}
        onSelectedValuesChange={handleSelectedValuesChange}
      />
    );
  };
  const thirdComponent = () => {
    return (
      <Third
        selectedValues={selectedValues}
        onSelectedValuesChange={handleSelectedValuesChange}
      />
    );
  };
  const [steps, setSteps] = useState([
    {
      key: "firstStep",
      label: "Price Calculation",
      isDone: true,
      component: firstComponent,
    },
    {
      key: "secondStep",
      label: "Order Details",
      isDone: false,
      component: secondComponent,
    },
    {
      key: "thirdStep",
      label: "Contact Info",
      isDone: false,
      component: thirdComponent,
    },
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleCreateorder = async () => {
    try {
      const token = localStorage.getItem("jwt");
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}orders/createorder`,
        {
          order: "Writing Service",
          academiclevel: selectedValues._academicLevel,
          deadline: selectedValues._deadline,
          instructions: selectedValues._instructions,
          pages: selectedValues._pageCount,
          references: selectedValues._references,
          spacing: selectedValues._spacing,
          style: selectedValues._style,
          subject: selectedValues._subject,
          time: selectedValues._time,
          topic: selectedValues._topic,
          papertype: selectedValues._typeOfPaper,
          total: _price,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const order_id = response.data.data.insertId;
      // console.log(
      //   "response.data",
      //   response.data,
      //   "response.data.insertId",
      //   response.data.data.insertId
      // );
      //       console.log("order created successful:", response.data);
      setError(null);
      if (order_id === null || order_id === undefined) {
        navigate(`/dashboard/orders/`);
      } else {
        navigate(`/dashboard/orders/${order_id}/payment`);
      }

    } catch (error) {
      console.error("Creating order error:", error);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    // Check if the current step is the second step and if the topic is empty
    if (
      steps[1].key === activeStep.key &&
      selectedValues._topic.trim() === ""
    ) {
      setError("Please enter a topic before proceeding.");
      return;
    } else {
      setError(null);
    }

    // Check if it's the last step
    if (steps[steps.length - 1].key === activeStep.key) {
      console.log("selectedValues", selectedValues);

      // alert("You have completed all steps.");
      handleCreateorder();
      return;
    }

    // Proceed to the next step
    const index = steps.findIndex((x) => x.key === activeStep.key);
    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = true;
        return x;
      })
    );
    setActiveStep(steps[index + 1]);
  };

  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  const { _wordCount, _price } = calculatePriceAndWords();

  return (
    <div className="box">
      <div className="steps">
        <ul className="nav">
          {steps.map((step, i) => {
            return (
              <li
                key={i}
                className={`fw-bold ${
                  activeStep.key === step.key ? "active" : ""
                } ${step.isDone ? "done" : ""}`}
              >
                <div>
                  <span>{step.label}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="step-component">
        {activeStep.component()}
        <hr></hr>
        <div className="row">
          <div className="col-md-6 mb-2">
            <span className="fw-bold">
              TOTAL WORDS{" "}
              <span className="text-primary">{_wordCount} Words</span>
            </span>
          </div>
          <div className="col-md-6 mb-2">
            <span className="fw-bold">
              TOTAL TO BE PAID{" "}
              <span className="text-primary">${_price.toFixed(2)}</span>
            </span>
          </div>
          <p>We accept credit cards and PayPal payments</p>
        </div>
      </div>
      <div className="p-2">
        {error && (
          <div
            className="alert alert-warning alert-dismissible fade show mt-2"
            role="alert"
          >
            {error}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
      </div>

      <div className="btn-component">
        {steps[0].key !== activeStep.key && (
          <button className="btn btn-secondary mb-2 pd-2" onClick={handleBack}>
            Back
          </button>
        )}
        <button className="btn btn-success mb-2 pd-2" onClick={handleNext}>
          {steps[steps.length - 1].key !== activeStep.key
            ? "Next"
            : loading
            ? "Submitting Order..."
            : "PROCEED TO PAYMENT"}
        </button>
      </div>
    </div>
  );
};

export default OrderWizard;
