import React from "react";
import CalculatorForm from "../../components/forms/CalculatorForm";

const homeBanner = () => {
  return (
    <div className="banner-area  home-image">
      <div className="homeoverlay">
        <div className="container-fluid">
          <div className="banner-shape-wrap position-relative z-1">
            <div className="banner-content" data-cues="slideInUp">
              <div className="row">
                <div className="col-md-9 mb-2">
                  {" "}
                  <span className="top-title">
                    Zero plagiarism papers, any deadline 3-24 hours
                  </span>
                  <h6>
                    <span className="badge text-bg-success">
                      24/7 customer care support
                    </span>
                    &nbsp;
                    <span className="badge text-bg-success">
                      100% money back guaranteed
                    </span>
                  </h6>
                  <h1>We specialise in</h1>
                  <div class="row global-content">
                    <div class="col-lg-6 col-sm-6">
                      <ul class="list-unstyled ps-0 mb-0 standards-list homelist">
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>Homework help</span>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>Take my exam</span>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>Take my quiz and test</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-sm-6">
                      <ul class="list-unstyled ps-0 mb-0 standards-list homelist">
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>Take my online class help</span>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>We do TEAS exam, HESI and GED</span>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex align-items-center">
                            <i class="flaticon-check-mark-2"></i>
                            <span>We do proctored and zoom exams.</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="banner-btn" data-cues="slideInUp">
                    <a href="/order" className="default-btn active">
                      <div className="d-flex align-items-center">
                        Click here to place your order
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-md-3 mb-2">
                  <CalculatorForm />
                </div>
              </div>
            </div>

            {/* <img
            src="assets/images/shape/shape-1.png"
            className="shape shape-1"
            alt="shape-1"
          />
          <img
            src="assets/images/shape/shape-2.png"
            className="shape shape-2"
            alt="shape-2"
          />
          <img
            src="assets/images/shape/shape-3.png"
            className="shape shape-3"
            alt="shape-3"
          />
          <img
            src="assets/images/shape/shape-4.png"
            className="shape shape-4"
            alt="shape-4"
          />
          <img
            src="assets/images/shape/shape-5.png"
            className="shape shape-5"
            alt="shape-5"
          />
          <img
            src="assets/images/shape/shape-6.png"
            className="shape shape-6"
            alt="shape-6"
          />
          <img
            src="assets/images/shape/shape-7.png"
            className="shape shape-7"
            alt="shape-7"
          />
          <img
            src="assets/images/shape/shape-8.png"
            className="shape shape-8"
            alt="shape-8"
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default homeBanner;
