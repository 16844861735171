import React from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";

const AboutUs = () => {
  return (
    <>
      <SideBarPageLayout pageTitle="About Us">
        <h2>The paper writing service you can trust</h2>
        <p>
          At essay pearl, we believe that all students should have access to
          high-quality written papers when they need them most.
        </p>
        <h3>Write my paper Papers from leading industry experts</h3>
        <p>
          Our key mission is to provide a reliable and accessible source of
          writing services that students can turn to for support when they need
          it. We understand the challenges that students face when it comes to
          academic writing, and we are committed to providing solutions that are
          both effective and affordable. Our team of experienced writers is
          dedicated to delivering well-written papers that meet the highest
          standards of academic excellence, helping students to achieve their
          goals and succeed in their studies. Whether you are struggling with a
          difficult assignment, working under a tight deadline, or simply need
          some extra help with your writing, we are here to support you and help
          you succeed.
        </p>
        <h3>Hire Our Quality writers</h3>
        <p>
          At essay pearl, we pride ourselves on the high quality of our writers.
          To ensure that our clients receive the best possible content, every
          writer who joins our team undergoes a rigorous testing process to
          determine their writing skills and professional capabilities. We are
          committed to hiring only the most talented and experienced writers to
          ensure that our clients receive content that meets their expectations
          and exceeds their goals. Our pro experts are dedicated to delivering
          top-notch work that reflects our commitment to excellence, and we are
          confident that you will be impressed with the quality of their
          writing.
        </p>
        <h3>Core values</h3>
        <div className="row">
          <div className="">
            <div className="row">
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-img-banner-wrapper">
                    <img
                      className="card-img-top"
                      src="https://www.essaypearl.com/assets/images/reliability.png"
                      alt="Card image cap"
                    />
                    <div className="card-img-banner">Reliability</div>
                  </div>
                  <div className="card-body">
                    <p>
                      At our company, we understand the importance of meeting
                      deadlines. We take pride in our ability to deliver our
                      work on time, every time, to ensure that our clients can
                      rely on us for timely completion of their projects.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-img-banner-wrapper">
                    <img
                      className="card-img-top"
                      src="https://www.essaypearl.com/assets/images/excellence.png"
                      alt="Card image cap"
                    />
                    <div className="card-img-banner">Excellence</div>
                  </div>
                  <div className="card-body">
                    <p>
                      Our commitment to excellence is evident in everything we
                      do. From the smallest task to the largest project, we
                      strive to deliver work of the highest quality, using our
                      expertise and experience to exceed our clients'
                      expectations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-img-banner-wrapper">
                    <img
                      className="card-img-top"
                      src="https://www.essaypearl.com/assets/images/confidentiality.png"
                      alt="Card image cap"
                    />
                    <div className="card-img-banner">Confidentiality</div>
                  </div>
                  <div className="card-body">
                    <p>
                      We take the privacy and security of our clients'
                      information seriously. Our strict policies ensure that
                      your personal information is never shared with any third
                      parties, giving you peace of mind and the freedom to work
                      with us without any concerns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <div className="card">
                  <div className="card-img-banner-wrapper">
                    <img
                      className="card-img-top"
                      src="https://www.essaypearl.com/assets/images/clarity.png"
                      alt="Card image cap"
                    />
                    <div className="card-img-banner">Clarity</div>
                  </div>
                  <div className="card-body">
                    <p>
                      Our pricing system is transparent and straightforward,
                      with no hidden fees or catches. We offer direct access to
                      our writers, allowing you to communicate your needs
                      clearly and effectively. And our 24/7 support team is
                      always available to answer any questions or concerns you
                      may have, providing a level of clarity and support that
                      sets us apart from our competitors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarPageLayout>
    </>
  );
};

export default AboutUs;
