import React, { useState, useEffect } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const OrderSolutions = ({ orderNumber }) => {
  const [solutions, setSolutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSolutions = async () => {
      try {
        const token = localStorage.getItem("jwt");
        const response = await axios.get(
          `${apiUrl}orders/getsolutions/${orderNumber}`,
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );
        setSolutions(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching solutions:", error);
        setError(
          "An error occurred while fetching solutions. Please try again."
        );
        setLoading(false);
      }
    };

    fetchSolutions();
  }, [orderNumber]);

  if (loading) {
    return <p>Loading solutions...</p>;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <>
      <hr />
      <p>Assignment Solutions</p>
      <ul className="list-group list-group-flush">
        {solutions.length === 0 ? (
          <li className="list-group-item text-danger">
            No solutions have been uploaded yet
          </li>
        ) : (
          solutions.map((solution, index) => (
            <li key={index} className="list-group-item">
              <a href={solution.url} target="_blank" rel="noopener noreferrer">
                {solution.u_filename}
              </a>
            </li>
          ))
        )}
      </ul>
    </>
  );
};

export default OrderSolutions;
