const samplesItems = [
  {
    title: "Undergraduate business samples",
    items: [
      {
        label: "Business Assignment",
        link: "/samples/files/Business-Assignment.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Business Dissertation",
        link: "/samples/files/Business-Dissertation.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Business essay",
        link: "/samples/files/Business-essay.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Business Reflective Essay",
        link: "/samples/files/Business-Reflective-Essay.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Business Report",
        link: "/samples/files/Business-Report.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Undergraduate psychology",
    items: [
      {
        label: "psychology Essay",
        link: "/samples/files/undergraduate-psychology-essay-sample.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "psychology essay sample",
        link: "/samples/files/psychology-essay-sample.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "psychology sample",
        link: "/samples/files/psychology-sample-sample.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Undergraduate Nursing Samples",
    items: [
      {
        label: "Nursing Essay",
        link: "/samples/files/The Nursing Management of Type 2 Diabetes Mellitus.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Nursing Essay",
        link: "/samples/files/Impact of PrEP on Individuals.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Nursing Essay",
        link: "/samples/files/How can promoting a positive NHS culture promote an increased quality of patient care.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Nursing Essay",
        link: "/samples/files/Discuss what effective leadership is and argue why it is deemed necessary in nursing practice.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Undergraduate Marketing Samples",
    items: [
      {
        label: "Marketing Report",
        link: "/samples/files/Marketing report.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Marketing Essay",
        link: "/samples/files/Marketing essay.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Marketing Assignment",
        link: "/samples/files/Marketing assignment.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Undergraduate Law Samples",
    items: [
      {
        label: "Law Essay",
        link: "/samples/files/law essay.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Law Report",
        link: "/samples/files/Law report.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Law assignment",
        link: "/samples/files/Law assignment.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Undergraduate Education Samples",
    items: [
      {
        label: "Educational essay",
        link: "/samples/files/educational essay.pdf",
        badge: "Undergraduate 1st",
      },
      {
        label: "Educational essay",
        link: "/samples/files/educational essays.pdf",
        badge: "Undergraduate 1st",
      },
    ],
  },
  {
    title: "Masters Nursing Samples",
    items: [
      {
        label: "Masters nursing sample essay",
        link: "/samples/files/masters nursing sample essay.pdf",
        badge: "Masters Distinction",
      },
      {
        label: "Masters nursing essay",
        link: "/samples/files/masters nursing essay.pdf",
        badge: "Masters Distinction",
      },
      {
        label: "Masters Nursing essay",
        link: "/samples/files/masters nursing samples essay.pdf",
        badge: "Masters Distinction",
      },
    ],
  },
  {
    title: "Masters Business Samples",
    items: [
      {
        label: "Business Essay",
        link: "/samples/files/Provide-a-broad-perspective-on-the-return-of-industrial-and-commercial-productivity-in-the-U.pdf",
        badge: "Masters Distinction",
      },
    ],
  },
];

export default samplesItems;

