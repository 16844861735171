import React from "react";
import CustomBreadCrump from "../../components/utils/breadcrump";
import { Link, useLocation } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import dashboardItems from "../lists/dashboardItems";
import LogoutButton from "../widgets/LogoutButton";

const DashboardLayout = ({ pageTitle, children }) => {
  const location = useLocation();

  const userName = localStorage.getItem("fullname");
  const userEmail = localStorage.getItem("email");

  return (
    <>
      <CustomBreadCrump pagetitle={pageTitle} />
      <div className="standard-area ptb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 mb-2">
              {/* Show user name and email */}

              <div className="card mb-2">
                {" "}
                <div className="card-body">
                  <h5>{userName}</h5>
                  <p>{userEmail}</p>
                </div>
              </div>
              {/* Render ListGroup */}
              <ListGroup className="mb-2">
                {dashboardItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.path}
                    className={`list-group-item ${
                      location.pathname === item.path ? "active" : ""
                    }`}
                  >
                    {item.label}
                  </Link>
                ))}
              </ListGroup>
              <LogoutButton />
            </div>
            <div className="col-md-10 mb-2">
              <div className="container">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
