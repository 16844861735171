// menuItems.js

const menuItems = [
  {
    label: "Home",
    link: "/",
  },
  { label: "How it Works", link: "/howitworks" },
  { label: "FAQs", link: "/faqs" },
  { label: "About Us", link: "/aboutus" },
  { label: "Pricing", link: "/pricing" },
  { label: "Samples", link: "/samples" },
  // {
  //   label: "Pages",
  //   subItems: [
  //     { label: "About Us", link: "about-us.html" },
  //     { label: "Pricing", link: "pricing.html" },
  //     {
  //       label: "Authentication",
  //       subItems: [
  //         { label: "Login", link: "login.html" },
  //         { label: "Register", link: "register.html" },
  //       ],
  //     },
  //     { label: "Reviews", link: "reviews.html" },
  //     { label: "FAQ", link: "faq.html" },
  //     { label: "Contact Us", link: "contact-us.html" },
  //     { label: "Privacy Policy", link: "privacy-policy.html" },
  //     { label: "Terms & Conditions", link: "terms-conditions.html" },
  //     { label: "404 Error", link: "error-404.html" },
  //   ],
  // },
];

export default menuItems;
