import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "./components/sections/Navbar/Navbar";
import Footer from "./components/sections/Footer/Footer";
import Home from "./pages/home/Home";

import "./App.css";
import HowitWorks from "./pages/howitworks/HowItWorks";
import FAQ from "./pages/faq/FAQ";
import AboutUs from "./pages/aboutus/AboutUs";
import Pricing from "./pages/pricing/Pricing";
import Samples from "./pages/samples/Samples";
import usePageTitle from "./components/utils/pagetitle";
import NotFound from "./pages/404/NotFound";
import OnlineClassHelp from "./pages/onlineclasshelp/OnlineClassHelp";
import Order from "./pages/order/Order";
import FooterCopyright from "./components/sections/Footer/FooterCopyright";
import Register from "./pages/register/Register";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import TermsandConditions from "./pages/termsandconditions/TermsandCondtions";

function Page(props) {
  const titlePrefix = " | ESSAYPEARL";
  usePageTitle(`${props.title}${titlePrefix}`);
  return props.content;
}



const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Navbar />
        <div className="main">
          <Routes>
            <Route
              path="/"
              element={<Page title="Home" content={<Home />} />}
            />
            <Route
              path="/howitworks"
              element={<Page title="How It Works" content={<HowitWorks />} />}
            />
            <Route
              path="/faqs"
              element={<Page title="FAQs" content={<FAQ />} />}
            />
            <Route
              path="/aboutus"
              element={<Page title="About Us" content={<AboutUs />} />}
            />
            <Route
              path="/pricing"
              element={<Page title="Pricing" content={<Pricing />} />}
            />
            <Route
              path="/samples"
              element={<Page title="Samples" content={<Samples />} />}
            />
            <Route
              path="/order/:academicLevel?/:typeOfPaper?/:deadline?/:pageCount?"
              element={<Page title="Create Order" content={<Order />} />}
            />

            <Route
              path="/onlineclasshelp"
              element={
                <Page title="Online Class Help" content={<OnlineClassHelp />} />
              }
            />
            <Route
              path="/register"
              element={<Page title="Register" content={<Register />} />}
            />
            <Route
              path="/login"
              element={<Page title="Login" content={<Login />} />}
            />
            <Route
              path="/dashboard/:dashpage?/:dashorder?/:dashaction?"
              element={<Page title="Dashboard" content={<Dashboard />} />}
            />
            <Route
              path="/termsandconditions"
              element={
                <Page
                  title="Terms  and Conditions"
                  content={<TermsandConditions />}
                />
              }
            />
            <Route
              path="*"
              element={
                <Page title="404 Page Not Found" content={<NotFound />} />
              }
            />
            {/* Add more routes as needed */}
          </Routes>
        </div>
        <Footer />
        <FooterCopyright />
        <FloatingWhatsApp
          phoneNumber="+19853170769"
          accountName="EssayPearl"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar="/assets/images/essayfav.png"
        />
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
