import React, { useState, useEffect } from "react";
import "../Order.css";
import First from "./First";
import Second from "./Second";
import orderItems from "../../lists/orderItems";
import { useNavigate } from "react-router-dom";

import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const OnlineClassWizard = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    _subject: orderItems.subjects[1].value,
    _type: orderItems.onlinetype[0].value,
    _deadline: "",
    _classData: {},
  });

  useEffect(() => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      _subject: orderItems.subjects[1].value,
      _type: orderItems.onlinetype[0].value,
      _deadline: "",
      _classData: {},
    }));
  }, []);

  const handleSelectedValuesChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const calculatePrice = () => {
    let selectedPrice = 0;

    switch (selectedValues._type) {
      case "Exam (160USD for an A)":
        selectedPrice = 160;
        break;
      case "Proctored exam (199USD for an A)":
        selectedPrice = 199;
        break;
      case "Test/Quiz (90USD)":
        selectedPrice = 90;
        break;
      case "Proctored Test/Quiz (120USD)":
        selectedPrice = 120;
        break;
      case "Managing 8 Weeks Class (800USD for an A)":
        selectedPrice = 800;
        break;
      case "Managing 11-14 Weeks Class (900USD for an A)":
        selectedPrice = 900;
        break;
      default:
        selectedPrice = 0;
        break;
    }

    return { _price: selectedPrice || 0 };
  };

  const firstComponent = () => {
    return (
      <First
        selectedValues={selectedValues}
        onSelectedValuesChange={handleSelectedValuesChange}
      />
    );
  };

  const secondComponent = () => {
    return (
      <Second
        selectedValues={selectedValues}
        onSelectedValuesChange={handleSelectedValuesChange}
      />
    );
  };

  const [steps, setSteps] = useState([
    {
      key: "firstStep",
      label: "Price Calculation",
      isDone: true,
      component: firstComponent,
    },
    {
      key: "secondStep",
      label: "Contact Info",
      isDone: false,
      component: secondComponent,
    },
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleCreateorder = async () => {
    try {
      const token = localStorage.getItem("jwt");
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}orders/createorder`,
        {
            order:"Online Tutoring",
          deadline: selectedValues._deadline,
          subject: selectedValues._subject,
          papertype: selectedValues._type,
          instructions: selectedValues._classData["instructions"],
          total: _price,
          data: {
            subject: selectedValues._subject,
            type: selectedValues._type,
            deadline: selectedValues._deadline,
            loginLink: selectedValues._classData["loginLink"],
            username: selectedValues._classData["username"],
            password: selectedValues._classData["password"],
            instructions: selectedValues._classData["instructions"],
          },
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      const order_id = response.data.data.insertId;
      // console.log(
      //   "response.data",
      //   response.data,
      //   "response.data.insertId",
      //   response.data.data.insertId
      // );
      //       console.log("order created successful:", response.data);
      setError(null);
      if (order_id === null || order_id === undefined) {
        navigate(`/dashboard/orders/`);
      } else {
        navigate(`/dashboard/orders/${order_id}/payment`);
      }
    } catch (error) {
      console.error("Creating order error:", error);
      setError(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

 const handleNext = () => {
   // Check if the current step is the first step
   if (steps[0].key === activeStep.key) {
     // Validate the class data
     const { loginLink, username, password } = selectedValues._classData;
     if (!loginLink || !username || !password) {
       setError(
         "Please provide login link, username, and password before proceeding."
       );
       return;
     } else {
       setError(null);
     }
   }

   // Check if it's the last step
   if (steps[steps.length - 1].key === activeStep.key) {
     // Proceed to handle order creation
     handleCreateorder();
     return;
   }

   // Proceed to the next step
   const index = steps.findIndex((x) => x.key === activeStep.key);
   setSteps((prevStep) =>
     prevStep.map((x) => {
       if (x.key === activeStep.key) x.isDone = true;
       return x;
     })
   );
   setActiveStep(steps[index + 1]);
 };


  const handleBack = () => {
    const index = steps.findIndex((x) => x.key === activeStep.key);
    if (index === 0) return;

    setSteps((prevStep) =>
      prevStep.map((x) => {
        if (x.key === activeStep.key) x.isDone = false;
        return x;
      })
    );
    setActiveStep(steps[index - 1]);
  };

  const { _price } = calculatePrice();
  return (
    <>
      <div className="box">
        <div className="steps">
          <ul className="nav">
            {steps.map((step, i) => {
              return (
                <li
                  key={i}
                  className={`fw-bold ${
                    activeStep.key === step.key ? "active" : ""
                  } ${step.isDone ? "done" : ""}`}
                >
                  <div>
                    <span>{step.label}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="step-component">
          {activeStep.component()}
          <hr></hr>
          <div className="row">
            <div className="col-md-12 mb-2">
              <span className="fw-bold">
                TOTAL TO BE PAID{" "}
                <span className="text-primary">${_price.toFixed(2)}</span>
              </span>
            </div>
            <p>We accept credit cards and PayPal payments</p>
          </div>
        </div>
        <div className="p-2">
          {error && (
            <div
              className="alert alert-warning alert-dismissible fade show mt-2"
              role="alert"
            >
              {error}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
        </div>

        <div className="btn-component">
          {steps[0].key !== activeStep.key && (
            <button
              className="btn btn-secondary mb-2 pd-2"
              onClick={handleBack}
            >
              Back
            </button>
          )}
          <button className="btn btn-success mb-2 pd-2" onClick={handleNext}>
            {steps[steps.length - 1].key !== activeStep.key
              ? "Next"
              : loading
              ? "Submitting Order..."
              : "PROCEED TO PAYMENT"}
          </button>
        </div>
      </div>
    </>
  );
};

export default OnlineClassWizard;
