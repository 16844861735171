import React, { useState, useEffect } from "react";
import PayPalPayment from "./paypal/PaypalPayment";
// import StripePayment from "./stripe/StripePayment";

import Spinner from "react-spinkit";
const apiUrl = process.env.REACT_APP_APIURL;

const availablePaymentMethods = [
  { id: "paypal", label: "PayPal", component: PayPalPayment },
  // { id: "stripe", label: "Stripe", component: StripePayment },
];

const PaymentsController = ({ orderNumber }) => {
  const [orderdetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch payments data from an endpoint
    fetchOrderData();
  }, []);

  const fetchOrderData = async () => {
    try {
      const token = localStorage.getItem("jwt");
      const response = await fetch(
        `${apiUrl}orders/orderdetails/${orderNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch order data");
      }
      const data = await response.json();
      setOrderDetails(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payments data:", error);
      setLoading(false);
    }
  };


  return (
    <div>
      <h2>Payment Details</h2>
      {loading && <Spinner name="circle" />}{" "}
      {/* Render spinner while loading */}
      {!loading && orderdetails.o_status !== 0 ? (
        <div className="alert alert-success" role="alert">
          Payment via {orderdetails.p_mode} has already been made for this order
          on {orderdetails.p_transtime}.
        </div>
      ) : (
        <>
          <div>
            {availablePaymentMethods.map((method) => (
              <div class="form-check form-check-inline" key={method.id}>
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    value={method.id}
                    checked={true}
                    disabled={true}
                  />
                  {method.label}
                </label>
              </div>
            ))}
          </div>
          <hr />
          <div>
            {/* {availablePaymentMethods.map((method) => (
              <div key={method.id}>
                {<method.component orderDetails={orderdetails} />}
              </div>
            ))} */}
            {!loading && orderdetails.o_status === 0 && (
              <div>
                <PayPalPayment orderDetails={orderdetails} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentsController;
