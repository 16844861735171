import React from "react";
import samplesItems from "../../components/lists/samplesitems";
import SideBarPageLayout from "../../components/utils/sidebarlayout";

const Samples = () => {
  return (
    <>
      <SideBarPageLayout pageTitle="Samples">
        <h2>Samples of Our Work</h2>
        <p>
          At tutors2hire , we're proud to showcase our quality and work. We've
          put together some sample academic papers, written by our expert
          writers to show their skills and quality. Take a look at what we can
          produce with the following examples created at a range of levels in
          our most popular subjects. Here is the quality you will expect after
          placing an order with us, take a look for yourself!
        </p>

        {samplesItems.map((section, index) => (
          <div key={index}>
            <h2>{section.title}</h2>
            <div className="list-group mb-3">
              {section.items.map((item, itemIndex) => (
                <a
                  key={itemIndex}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  href={item.link}
                >
                  {item.label}
                  <span className="badge badge-secondary">{item.badge}</span>
                </a>
              ))}
            </div>
          </div>
        ))}
      </SideBarPageLayout>
    </>
  );
};

export default Samples;
