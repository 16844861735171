import React from "react";
import SideBarPageLayout from "../../components/utils/sidebarlayout";
import RegisterForm from "../../components/forms/RegisterForm";

const Register = () => {
  return (
    <>
      <SideBarPageLayout pageTitle="Register">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body">
                <h3>Create Your Account</h3>
                <RegisterForm />
                <p className="text-small">
                  Already have an account?
                  <a href="/login" className="thelink">
                    {" "}
                    Sign in here.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </SideBarPageLayout>
    </>
  );
};

export default Register;
