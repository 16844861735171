import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import exam1 from "../../images/exam1.jpg";
import exam4 from "../../images/exam4.jpg";
import exam5 from "../../images/exam5.jpg";
import exam6 from "../../images/exam6.jpg";
import exam7 from "../../images/exam7.jpg";
import exam8 from "../../images/exam8.jpg";

const HomeAbout = () => {
  return (
    <div className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="about-img position-relative z-1"
              data-cues="slideInLeft"
            >
              <Carousel showArrows={true} showThumbs={false} autoPlay={true}>
                <div>
                  <img src={exam1} alt="exam1" />
                </div>
                <div>
                  <img src={exam4} alt="exam4" />
                </div>
                <div>
                  <img src={exam5} alt="exam5" />
                </div>
                <div>
                  <img src={exam6} alt="exam6" />
                </div>
                <div>
                  <img src={exam7} alt="exam7" />
                </div>
                <div>
                  <img src={exam8} alt="exam8" />
                </div>
              </Carousel>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="global-content about-content mw-668 position-relative z-2"
              data-cues="slideInUp"
            >
              <span className="top-title">About Us</span>
              <h2>Online class help and Exam help</h2>
              <p>
                EssayPearl the leading US-based provider of Do My Homework and
                Take My Online Class services with servers in UK and canada.
                Founded in 2010, we’ve completed over 17,000 assignments for our
                clients. We complete quizzes, tests, exams, projects, reports,
                essays, modules, and even entire courses in nearly every
                subject. We’d be delighted to serve you on your next online
                course or assignment. Amazing offer for all classes (all prices
                quoted are per week): One week- 99$, Online exam -99$ Quiz and
                texts -69$ Place your order for class or exam, prices for online
                class are per week, It’s an A or B or a refund.
              </p>
              <div className="row">
                <div className="col-lg-6 col-sm-6">
                  <ul className="list-unstyled ps-0 mb-0 standards-list">
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="flaticon-check-mark-2"></i>
                        <span>Quality Guaranteed</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="flaticon-check-mark-2"></i>
                        <span>Skillful Writers Only</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <ul className="list-unstyled ps-0 mb-0 standards-list">
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="flaticon-check-mark-2"></i>
                        <span>Money Back Guarantee</span>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center">
                        <i className="flaticon-check-mark-2"></i>
                        <span>Article Writing</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <a href="/ordernow" className="default-btn about-btn">
                Place Online Class Help Order
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
