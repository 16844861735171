import React, { useState, useEffect } from "react";
import Select from "react-select";

import orderItems from "../../lists/orderItems";

const Second = ({ selectedValues, onSelectedValuesChange }) => {
  const [selectedStyle, setSelectedStyle] = useState("");
  const [enteredTopic, setTopic] = useState("");
  const [enteredReferences, setReferences] = useState("");
  const [enteredInstructions, setInstructions] = useState("");

  useEffect(() => {
    setSelectedStyle(selectedValues._style);
    setTopic(selectedValues._topic);
    setReferences(selectedValues._refrences);
    setInstructions(selectedValues._instructions);
  }, [selectedValues]);

  const handleSelectChange = (key, value) => {
    onSelectedValuesChange(key, value);

    switch (key) {
      case "_style":
        setSelectedStyle(value);
        break;
      default:
        break;
    }
  };

  const handleTopicChange = (event) => {
    setTopic(event.target.value);
    onSelectedValuesChange("_topic", event.target.value);
  };
   const handleReferencesChange = (event) => {
     setReferences(event.target.value);
     onSelectedValuesChange("_references", event.target.value);
   };

  const handleInstructionsChange = (event) => {
    setInstructions(event.target.value);
    onSelectedValuesChange("_instructions", event.target.value);
  };

  const styleOptions = orderItems.style;
  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-2">
          <label>Topic</label>
          <input
            placeholder="Enter your topic here"
            className="form-control"
            value={enteredTopic}
            onChange={handleTopicChange}
          />
        </div>
        <div className="col-md-2 mb-2">
          <label>References</label>
          <input
            placeholder="0"
            className="form-control"
            value={enteredReferences}
            type="number"
            min={0}
            onChange={handleReferencesChange}
          />
        </div>
        <div className="col-md-4 mb-2">
          <label>Select Writing Style</label>
          <Select
            options={styleOptions}
            value={{
              value: selectedStyle,
              label: selectedStyle,
            }}
            onChange={(selectedOption) =>
              handleSelectChange("_style", selectedOption.value)
            }
            placeholder="Select Writing Style"
          />
        </div>
        <div className="col-md-12 mb-2">
          <label>Instructions</label>
          <textarea
            placeholder="Enter your instructions here"
            className="form-control"
            value={enteredInstructions}
            onChange={handleInstructionsChange}
          />
        </div>
        <div className="alert alert-info">
          You will be able to upload additional files after submitting your
          order
        </div>
      </div>
    </>
  );
};
export default Second;
