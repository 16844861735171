import React, { useState, useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";

const apiUrl = process.env.REACT_APP_APIURL;

const UpdateProfileForm = ({ userData }) => {
  console.log("userData", userData);
  const [fullnames, setFullNames] = useState(userData?.u_fullnames || "");
  const [email] = useState(userData?.u_email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData?.u_phone || "");
  const [address, setAddress] = useState(userData?.u_address || "");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");


  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression for phone number validation
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleRegister = async () => {
    try {
      setError("");
      setMessage("");
      if (!fullnames || !email || !phoneNumber || !address) {
        setError("All fields are required");
        return;
      }


      if (!validatePhoneNumber(phoneNumber)) {
        setError("Please enter a valid phone number");
        return;
      }

      setLoading(true);
      const token = localStorage.getItem("jwt");
      const response = await axios.post(
        `${apiUrl}user/updateprofile`,
        {
          u_fullnames: fullnames,
          u_phone: phoneNumber,
          u_address:address,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setMessage(response.data.message);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        setMessage(`An error occurred: ${error.response.data.message}`);
      } else {
        setMessage("An error occurred. Please try again.");
      }
      setLoading(false);
    }
  };

  return (
    <>
      <h5>Update Account Details</h5>
      {message && (
        <div
          className={`alert ${
            message.includes("successfully") ? "alert-success" : "alert-danger"
          }`}
        >
          {message}
        </div>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
      <form>
        <div className="row">
          <div className="form-group col-md-6 mb-2">
            <label>Full Names:</label>
            <input
              type="text"
              className="form-control"
              value={fullnames}
              onChange={(e) => setFullNames(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6 mb-2">
            <label>Email Address:</label>
            <input
              type="text"
              className="form-control"
              value={email}
              disabled
            />
          </div>
          <div className="form-group col-md-6 mb-2">
            <label>Phone Number:</label>
            <PhoneInput
              defaultCountry="us"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
            />
          </div>
          <div className="form-group col-md-6 mb-2">
            <label>Address:</label>
            <input
              type="text"
              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6 mb-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update Profile"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateProfileForm;
